if (document.getElementById('articleBoxText')) {

	function removeStyles(el) {
	    el.removeAttribute('style')

	    el.childeNodes.forEach(x => {
	        if(x.nodeType == 1) removeStyles(x)
	    })
	}

	function allDescendants (node) {
	    for (var i = 0; i < node.childNodes.length; i++) {
	      var child = node.childNodes[i];
	      allDescendants(child);
	      removeStyles(child);
	    }
	}

	let element = document.getElementById('#articleBoxText');
	allDescendants(element)
}