import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import LoginCard from './Cards/LoginCard';
import Footer from '../Footer';
import MenuBar from '../MenuBar';


let LoginContainer = styled.div`
    display: grid;
    grid-template-rows: 1200px 44px;
    grid-template-columns: 1fr;
    transform: rotate(15);

    @media(max-width: 600px) {
        grid-template-rows: 245px 100px 100px 1fr 44px;
    }
`;


let BlogMagicBackground = styled.div`
    background-image: url(../images/BlogBackground.svg);
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    
    display: grid;
    grid-template-rows: 80px 1fr;

    @media(max-width: 600px) {
       background-size: 270%;
    }
`;

let MenuBarWrapper = styled.div`
    width: 100%;
    align-self: center;
    justify-self: center;
    display: grid;
    grid-template-rows: 80px;
    grid-row: 1;
    grid-column: 1;


    @media(max-width: 600px) {
       align-self: normal;
       height: 270px;
    }
`;

let Section = styled.div`
    display: grid;
    justify-self: center;
`;

export default class Login extends Component {

    constructor(props){
        super(props)
    }

    render() {


        return (
                <LoginContainer>
                        <BlogMagicBackground>
                            <MenuBarWrapper>
                                    <MenuBar />
                            </MenuBarWrapper>
                            <Section>
                                <LoginCard action={ this.props.action } csrf={ this.props.csrf } />
                            </Section>
                        </BlogMagicBackground>
                        <Footer />
                </LoginContainer>
        );
    }
}

if (document.getElementById('login')) {
    let csrf = document.getElementById('login').getAttribute('csrf');
    let action = document.getElementById('login').getAttribute('action');
    ReactDOM.render(<Login csrf={csrf} action={action} />, document.getElementById('login'));
}