import React, { Component } from 'react';
import ReactDOM from 'react-dom';
require('./Footer.css')

class Footer extends Component {

    constructor(props){
        super(props)
    }

    render(){
        return(
        <div className="footer">
            <p>Copyright ©2019-2021 Iflet.tech. All rights reserved.</p>
        </div>
        );
    }
}

export default Footer;

if (document.getElementById('Footer')) {
    ReactDOM.render(<Footer />, document.getElementById('Footer'));
}