import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Section from './Section';
import Footer from '../Footer';
import MenuBar from '../MenuBar';

let WellcomeContainer = styled.div``;
let WellcomeMagicBackground = styled.div`
    background: linear-gradient(180.2deg,
                                rgba(242, 246, 255, 0) 18.15%,
                                rgba(47, 87, 176, 0.518792) 55.4%,
                                rgba(242, 246, 255, 0.89) 82.05%),

                linear-gradient(100.57deg,
                                #1565C0 9.47%,
                                rgba(40, 208, 188, 0.89) 110.56%),
                linear-gradient(180.2deg, rgba(242, 246, 255, 0) 18.15%, rgba(242, 246, 255, 0.89) 82.05%),
                linear-gradient(100.57deg, #1565C0 9.47%, rgba(40, 208, 188, 0.89) 110.56%);
    

    display: grid;
    grid-template-rows: 80px 1000px;
    grid-template-columns: 1fr;

    @media (max-width: 600px) {
        grid-template-rows: 1fr 700px;
    }

`;

let MenuBarWrapper = styled.div`
        width: 100%;
        height: 80px;
        align-self: center;
        justify-self: center;
        display: grid;

        @media (max-width: 600px) {
            align-self: normal;
        }
`;

export default class Wellcome extends Component {

    constructor(props) {
        super(props)

        console.log(this.props.logoutLiteral)
    }

    render() {


        return (
                <WellcomeContainer>
                    <WellcomeMagicBackground>
                        <MenuBarWrapper> 
                            <MenuBar 
                                username={this.props.username}
			                    csrf={ this.props.csrf} 
			                    logoutLiteral={ this.props.logoutLiteral }  
			                    logoutRoute={ this.props.logoutRoute }
                                loginLiteral={ this.props.loginLiteral }
                                loginRoute={ this.props.loginRoute}
                                registerLiteral={ this.props.registerLiteral}
                                registerRoute={ this.props.registerRoute}
                                adminLevel = {this.props.adminLevel}
                                panelRoute = {this.props.panelRoute}
                                panelLiteral = {this.props.panelLiteral}
                            />
                        </MenuBarWrapper>
                        <Section />
                    </WellcomeMagicBackground>
                    <Footer />
                </WellcomeContainer>
        );
    }
}

if (document.getElementById('wellcome')) {

    let wellcome = document.getElementById('wellcome');
    let username = wellcome.getAttribute('username');
    let csrf = wellcome.getAttribute('csrf');
    let loginRoute = wellcome.getAttribute('loginRoute');
    let logoutRoute = wellcome.getAttribute('logoutRoute');
    let registerRoute = wellcome.getAttribute('registerRoute');
    let logoutLiteral = wellcome.getAttribute('logoutLiteral');
    let loginLiteral = wellcome.getAttribute('loginLiteral');
    let registerLiteral = wellcome.getAttribute('registerLiteral');
    let adminLevel = wellcome.getAttribute('adminLevel');
    let panelRoute = wellcome.getAttribute('panelRoute');
    let panelLiteral = wellcome.getAttribute('panelLiteral');
    
    ReactDOM.render(<Wellcome   username={username} 
                                csrf = {csrf}
                                loginRoute = {loginRoute}
                                logoutRoute = {logoutRoute}
                                registerRoute = {registerRoute}
                                logoutLiteral = {logoutLiteral}
                                loginLiteral = {loginLiteral}
                                registerLiteral = {registerLiteral}
                                adminLevel = {adminLevel}
                                panelRoute = {panelRoute}
                                panelLiteral = {panelLiteral}
                    />, document.getElementById('wellcome'));
}