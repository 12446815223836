import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import BlogBody from './BlogBody';
import styled from 'styled-components';
import MenuBar from '../MenuBar';


let BlogWrapper = styled.div`
    display: grid;
`;

let BlogHeader = styled.div`
    display: grid;
    grid-template-rows: 80px 100px 100px 1fr 44px;
    grid-template-columns: 1fr;
    transform: rotate(15);

    @media(max-width: 600px) {
        grid-template-rows: 80px 100px 100px 1fr 44px;
    }

`;

let BlogMagicBackground = styled.div`
    background-image: url(../images/BlogBackground.svg);
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    grid-row: 1 /span 4;
    grid-column: 1 /span 1;

    @media(max-width: 600px) {
       background-size: 310%;
    }
`;

let MenuBarWrapper = styled.div`
    width: 100%;
    align-self: center;
    justify-self: center;
    display: grid;
    grid-row: 1;
    grid-column: 1;


    @media(max-width: 600px) {
       align-self: normal;
    }
`;


let BlogTitle = styled.div`
    grid-row: 2 / span 1;
    grid-column: 1;
    z-index: 10;
    color: white;
    font-size: 80px;
    font-weight: 800;
    align-self: center;
    justify-self: center;

`;

let BlogBodyWrapper = styled.div`
    grid-column: 1;
    grid-row: 4;
    justify-content: center;
    margin-bottom: 45px;
`;


export default class Blog extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
                <BlogWrapper>
                    <BlogHeader id="blogHeader">
                        <BlogMagicBackground />
                        <MenuBarWrapper>
                            <MenuBar 
                                    username={this.props.username}
                                    csrf={ this.props.csrf} 
                                    logoutLiteral={ this.props.logoutLiteral }  
                                    logoutRoute={ this.props.logoutRoute }
                                    loginLiteral={ this.props.loginLiteral }
                                    loginRoute={ this.props.loginRoute}
                                    registerLiteral={ this.props.registerLiteral}
                                    registerRoute={ this.props.registerRoute}
                                    adminLevel = {this.props.adminLevel}
                                    panelRoute = {this.props.panelRoute}
                                    panelLiteral = {this.props.panelLiteral}
                                />
                        </MenuBarWrapper>
                        <BlogTitle>Blog</BlogTitle>
                        <BlogBodyWrapper>
                            <BlogBody 
                                articles={this.props.articles} 
                            />
                        </BlogBodyWrapper>
                        <Footer />
                    </BlogHeader>
                    
                    
                </BlogWrapper>
        );
    }
}

if (document.getElementById('blog')) {
    let blog = document.getElementById('blog');
    let articles = blog.getAttribute('articles');
    let username = blog.getAttribute('username');
    let csrf = blog.getAttribute('csrf');
    let loginRoute = blog.getAttribute('loginRoute');
    let logoutRoute = blog.getAttribute('logoutRoute');
    let registerRoute = blog.getAttribute('registerRoute');
    let logoutLiteral = blog.getAttribute('logoutLiteral');
    let loginLiteral = blog.getAttribute('loginLiteral');
    let registerLiteral = blog.getAttribute('registerLiteral');
    let adminLevel = blog.getAttribute('adminLevel');
    let panelRoute = blog.getAttribute('panelRoute');
    let panelLiteral = blog.getAttribute('panelLiteral');
    
    ReactDOM.render(<Blog   username={username} 
                            articles={articles} 
                            csrf = {csrf}
                            loginRoute = {loginRoute}
                            logoutRoute = {logoutRoute}
                            registerRoute = {registerRoute}
                            logoutLiteral = {logoutLiteral}
                            loginLiteral = {loginLiteral}
                            registerLiteral = {registerLiteral}
                            adminLevel = {adminLevel}
                            panelRoute = {panelRoute}
                            panelLiteral = {panelLiteral}
                    />, document.getElementById('blog'));
}