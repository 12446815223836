import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import HeaderMenu from '../HeaderMenu';
import Footer from '../Footer';
import PortfolioBody from './PortfolioBody';
import styled from 'styled-components';
import MenuBar from '../MenuBar';


let PortfolioContainer = styled.div`
        background-color: #F2F6FF;
        display: grid;
        justify-items: center;

`;

let PortfolioMagicBackground = styled.div`
    background-image: url(../images/PortfolioBackground.svg);
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 100px 300px 1fr;

    @media (max-width: 600px) {
        background-size: 300%;
        background-position-x: -200px;
    }
`;

let MenuBarWrapper = styled.div`
        width: 100%;
        align-self: center;
        justify-self: center;
        display: grid;

        @media (max-width: 600px) {
            align-self: normal;
        }
`;

let PortfolioTitle = styled.div`
    color: white;
    font-size: 80px;
    font-weight: 800;
    justify-self: center;
    align-self: center;

    @media (max-width: 600px) {
        font-size: 50px;
    }


`;

let PortfolioDraw = styled.div`
    background-image: url(../images/PortfolioDraw.svg);
    width: 100%;
    height: 280px;
    align-self: center;
    background-size: 100% 100%;
    z-index: 10;

    @media (max-width: 600px) {
        width: 80%;
        justify-self: center;
    }
`;


export default class Portfolio extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
                <PortfolioContainer>
                        <PortfolioMagicBackground id="portfolioMagicBackground">
                                <MenuBarWrapper>
                                    <MenuBar 
                                        username={this.props.username}
                                        csrf={ this.props.csrf} 
                                        logoutLiteral={ this.props.logoutLiteral }  
                                        logoutRoute={ this.props.logoutRoute }
                                        loginLiteral={ this.props.loginLiteral }
                                        loginRoute={ this.props.loginRoute}
                                        registerLiteral={ this.props.registerLiteral}
                                        registerRoute={ this.props.registerRoute}
                                        adminLevel = {this.props.adminLevel}
                                        panelRoute = {this.props.panelRoute}
                                        panelLiteral = {this.props.panelLiteral}
                                    />
                                </MenuBarWrapper>
                                <PortfolioTitle>PortFolio</PortfolioTitle>
                                <PortfolioDraw />
                                <PortfolioBody 
                                portfolios={this.props.portfolios} 
                                />
                        </PortfolioMagicBackground>
                <Footer />
                </PortfolioContainer>

        );
    }
}

if (document.getElementById('portfolio')) {
    let portfolio = document.getElementById('portfolio');
    let portfolios = portfolio.getAttribute('portfolios');
    let username = portfolio.getAttribute('username');
    let csrf = portfolio.getAttribute('csrf');
    let loginRoute = portfolio.getAttribute('loginRoute');
    let logoutRoute = portfolio.getAttribute('logoutRoute');
    let registerRoute = portfolio.getAttribute('registerRoute');
    let logoutLiteral = portfolio.getAttribute('logoutLiteral');
    let loginLiteral = portfolio.getAttribute('loginLiteral');
    let registerLiteral = portfolio.getAttribute('registerLiteral');
    let adminLevel = portfolio.getAttribute('adminLevel');
    let panelRoute = portfolio.getAttribute('panelRoute');
    let panelLiteral = portfolio.getAttribute('panelLiteral');
    
    ReactDOM.render(<Portfolio  username={username} 
                                portfolios={portfolios}
                                csrf = {csrf}
                                loginRoute = {loginRoute}
                                logoutRoute = {logoutRoute}
                                registerRoute = {registerRoute}
                                logoutLiteral = {logoutLiteral}
                                loginLiteral = {loginLiteral}
                                registerLiteral = {registerLiteral}
                                adminLevel = {adminLevel}
                                panelRoute = {panelRoute}
                                panelLiteral = {panelLiteral}
                    />, document.getElementById('portfolio'));
}