import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PanelCard from './PanelCard';


let PanelCardsContainer = styled.div`
	display: grid;
	justify-items: center;
	padding: 100px 0;
`;

let PanelCardsColumn = styled.div`

	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: center;
	align-items: center;
	grid-gap: 60px;
`;

export default class PanelCards extends Component {

	constructor(props){
		super(props)
	}

    render() {

    	console.log(this.props.articles)
		let articles =  JSON.parse(this.props.articles);

        return (
        	<PanelCardsContainer>
        		<PanelCardsColumn>
        			{ articles.map( (article) =>
						<PanelCard csrf={this.props.csrf}
								   article = {article}
			    				   creationDateLiteral = {this.props.creationDateLiteral}
			    				   modifyDateLiteral = {this.props.modifyDateLiteral}
			    				   editaLiteral = {this.props.editaLiteral}
			    				   borraLiteral = {this.props.borraLiteral}
			    				   editaRoute = {this.props.editaRoute}
			    				   borraRoute = {this.props.borraRoute}
						/> 
					)}
        		</PanelCardsColumn>
        	</PanelCardsContainer>      
		); 
    }
}



if (document.getElementById('PanelCards')) {
	let panelCards = document.getElementById('PanelCards');
    let csrf = panelCards.getAttribute('csrf');
    let articles = panelCards.getAttribute('articles');
    let creationDateLiteral = panelCards.getAttribute('creationDateLiteral');
    let modifyDateLiteral = panelCards.getAttribute('modifyDateLiteral');
    let editaLiteral = panelCards.getAttribute('editaLiteral');
    let borraLiteral = panelCards.getAttribute('borraLiteral');
    let editaRoute = panelCards.getAttribute('editaRoute');
    let borraRoute = panelCards.getAttribute('borraRoute');
    
    
    ReactDOM.render(<PanelCards
    					csrf = {csrf}
    					articles = {articles}
    					creationDateLiteral = { creationDateLiteral }
    					modifyDateLiteral = { modifyDateLiteral }
    					editaLiteral = {editaLiteral}
    					borraLiteral = {borraLiteral}
    					editaRoute = {editaRoute}
    					borraRoute = {borraRoute}
    				 />, document.getElementById('PanelCards'));
}