import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';


let PanelCardWrapper = styled.div`
	width: 360px;
	// height: 470px;
	z-index: 10;
	background: #9C4DCC;
	border-radius: 30px;
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
	display: grid;
		&:hover {
			transform: scale(1.05, 1.05);
			box-shadow: 0 50px 80px rgba(0, 0, 0, 0.3);
		}
	transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
		& + a {
			display: grid;
			grid-template-rows: 130px 4fr;
		}
`;

let PanelCardTitle = styled.div`
	color: white;
	font-weight: bold;
	font-size: 32px;
	line-height: 38px;
	padding: 36px;
	text-align: center;

	align-self: center;
	justify-self:center;

`;

let PanelCardData = styled.div`
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(40px);
    border-radius: 0 0 30px 30px;
    padding: 20px 36px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;

`;

let AuthorCardSection = styled.div`
	display: grid;
	grid-template-columns: 80px 1fr;
	align-items: center;
	justify-items: center;

`;

let AuthorImage = styled.div`
	width: 60px;
	height: 60px;
	background-image: url("https://gravatar.com/avatar/0815692b7f41f457794ce8bc89ab8c79?s=400&d=robohash&r=x");
	background-size: contain;
	border-style: solid;
    border-radius: 50%;
    border-color: white;
}
`;

let AuthorName = styled.div`
	color: white;
	font-size: 20px;
	font-weight: 600;
	justify-self: start;
`;

let FechaCreacionCardSection = styled.div`
	align-self: center;
	justify-self: center;
	color: white;
	display: grid;
	grid-template-rows: 1fr 1fr;
	font-size: 20px;
	line-height: 24px;
`;
let PanelCardCreationDate = styled.div`
	font-weight: 500px;
`;

let FechaModifCardSection = styled.div`
	align-self: center;
	justify-self: center;
	color: white;
	display: grid;
	grid-template-rows: 1fr 1fr;
	font-size: 20px;
	line-height: 24px;
`;
let PanelCardModifDate = styled.div`
	font-weight: 500px;
`;

let ButtonsCardsection = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-items: center;
	align-self: center;
`;

let EditaButton = styled.button`
	background: #1565C0;
    color: white;
    font-weight: 500;
    font-size: 18px;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    height: 40px;
    padding: 0px 24px;
    border: 0;
    text-transform: uppercase;

     & a:hover {
    	color: white;
    }
`;

let BorraButton = styled.button`
	background: white;
	color: #1565C0;
    font-weight: 500;
    font-size: 18px;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    height: 40px;
    padding: 0px 24px;
    border: 0;
    text-transform: uppercase;

    & a {
    	color: #1565C0;
    }

    & a:hover {
    	color: blue;
    }

`;

let EditaForm = styled.form``;
let BorraForm = styled.form``;


export default class PanelCard extends Component {

	constructor(props){
		super(props)

		this.state = {
			// editaRoute: this.props.editaRoute + "/" + this.props.article.slug,
			// borraRoute: this.props.borraRoute + "/" + this.props.article.slug,
			editaRoute: "edit/" + this.props.article.slug,
			borraRoute: "article/" + this.props.article.slug,
		}
	}

    render() {

        return (
            <PanelCardWrapper>
					<PanelCardTitle> {this.props.article.title} </PanelCardTitle>
					
					<PanelCardData>
						<AuthorCardSection>
							<AuthorImage />
							<AuthorName> {this.props.article.author} </AuthorName>
						</AuthorCardSection>

						<FechaCreacionCardSection>
							<div><strong> {this.props.creationDateLiteral} </strong></div>
							<PanelCardCreationDate> {this.props.article.created_at} </PanelCardCreationDate>
						</FechaCreacionCardSection>
							
						<FechaModifCardSection>
							<div><strong> {this.props.modifyDateLiteral} </strong></div>
							<PanelCardModifDate> {this.props.article.updated_at} </PanelCardModifDate>
						</FechaModifCardSection>

						<ButtonsCardsection>
							<EditaButton type="submit" form="EditaForm" > {this.props.editaLiteral} </EditaButton>
							<BorraButton type="submit" form ="BorraForm">  {this.props.borraLiteral} </BorraButton>
						</ButtonsCardsection>
							
					</PanelCardData>

					<EditaForm id="EditaForm" action={this.state.editaRoute} method="GET">
							<input type="hidden" name="_token" id="csrf-token" value={this.props.csrf} />
					</EditaForm>

					<BorraForm id="BorraForm" action={this.state.borraRoute} method="POST">
						<input type="hidden" name="_token" id="csrf-token" value={this.props.csrf} />
						<input type="hidden" name="_method" value="DELETE" />
					</BorraForm>

					{/* <div class="artcile-fecha">{{ \Carbon\Carbon::createFromFormat('Y-m-d H:i:s', $article->created_at )->toDayDateTimeString() }}	{{ $article->user->alias }}</div> */}
					
			</PanelCardWrapper>         
		); 
    }
}



if (document.getElementById('PanelCard')) {
	let panelCard = document.getElementById('PanelCard');
    let csrf = panelCard.getAttribute('csrf');
    let article = panelCard.getAttribute('article');
    let creationDateLiteral = panelCard.getAttribute('creationDateLiteral');
    let modifyDateLiteral = panelCard.getAttribute('modifyDateLiteral');
    let editaLiteral = panelCard.getAttribute('editaLiteral');
    let borraLiteral = panelCard.getAttribute('borraLiteral');
    let editaRoute = panelCard.getAttribute('editaRoute');
    let borraRoute = panelCard.getAttribute('borraRoute');
    
    
    ReactDOM.render(<PanelCard
    					csrf = { csrf }
    					article = { article }
    					creationDateLiteral = { creationDateLiteral }
    					modifyDateLiteral = { modifyDateLiteral }
    					editaLiteral = {editaLiteral}
    					borraLiteral = {borraLiteral}
    					editaRoute = {editaRoute}
    					borraRoute = {borraRoute}
    				 />, document.getElementById('PanelCard'));
}