import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

export default class FormField extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let FormFieldWrapper = styled.label`
            display: grid;
            grid-template-rows: 30px;
            grid-template-columns: 110px 330px;
            align-items: center;
            column-gap: 16px;
        `;
        let FormFieldText = styled.div`
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            justify-self: end;
        `;
        let FormFieldInput = styled.input`
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 5px;
            border: 0;
            padding: 0 1rem;
            text-align: center;
        `;

        return (
            <FormFieldWrapper>
                <FormFieldText>{ this.props.name }</FormFieldText>
                <FormFieldInput id={this.props.id} type={ this.props.type } value={ this.props.value } onChange={this.props.onChange}></FormFieldInput>
            </FormFieldWrapper>

        );
    }
}