import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MenuBar from './MenuBar';
import styled from 'styled-components';


class HeaderMenu extends Component {

    render() {

        let HeaderMenuWrapper = styled.div`
                width: 100%;
                align-self: center;
                justify-self: center;
                display: grid;
                grid-template-rows: 80px;
        `;

        return (
            <HeaderMenuWrapper>
                <MenuBar />
            </HeaderMenuWrapper>
        
        );
    }
}

export default HeaderMenu;