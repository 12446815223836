import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
// import BlogCard from './PortfolioCard';


class BlogBody extends Component {

    render() {

        let PortfolioBody = styled.div`
            display: grid;
            grid-template-rows: 800px;
        `;

        let UnderConstructionMessage = styled.div`
            font-size: 60px;
            font-weight: 900;
            align-self: center;
            justify-self: center;
            text-align: center;

            @media (max-width: 600px){
                font-size: 40px;
            }
        `;

		let portfoliosObject = JSON.parse(this.props.portfolios);

        return (

            <PortfolioBody>
                <UnderConstructionMessage>
                    Espacio en construcción
                </UnderConstructionMessage>
				{/* { portfoliosObject.map((portfolio, index) => (
								<PortFolioCard
									key = {index}
									title = {portfolio.title}
									slug = { portfolio.slug }
									// text =  {portfolio.body}
									createdAt = { portfolio.created_at }
                                    
								/> )
                )} */}
            </PortfolioBody>
        );
    }
}

export default BlogBody;