import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Register from '../Register';
import FormField from './FormField';
import axios from 'axios';

export default class LoginCard extends Component {

    render() {

        let RegisterCardWrapper = styled.div`
            width: 570px;
            height: 470px;
            filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.2));
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(40px);
            border-radius: 30px;
            box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);

            display: grid;
            grid-template-rows: 140px 1fr;
        
        `;

        let RegisterCardTitle = styled.div`
            font-weight: bold;
            font-size: 80px;
            line-height: 95px;
            align-self: center;
            justify-self: center;
            color: #1565C0;
        `;

        let RegisterCardForm = styled.form`
            display: grid;
            grid-template-rows: repeat(5, 48px) 60px;
            justify-items: center;
            align-items: center;
        `;

        let RegisterCardSubmit = styled.input`
            background: #1565C0;
            color: white;
            font-weight: 500;
            font-size: 18px;
            box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            display: grid;
            height: 40px;
            padding: 0px 24px;
            border: 0;

            justify-self: start;
            align-self: center;

        `;

        let RegisterSubmitRow = styled.div`
            justify-self: center;
            align-self: center;
            display: grid;
            grid-template-columns: 200px;

        `;
    
        return (
            <RegisterCardWrapper>
                <RegisterCardTitle>Register</RegisterCardTitle>
                <RegisterCardForm method="POST" action= { this.props.action }>
                    <input type="hidden" name="_token" value={ this.props.csrf } />            
                    {/* <FormField id="name" type="text" name="name" value={ this.props.oldname } required autofocus /> */}
                    <FormField type="text" name="name" value=""  required autofocus />
                    <FormField type="text" name="alias" value=""  required autofocus />
                    <FormField type="text" name="email" value=""  required />
                    <FormField type="password" name ="password" value="" required/>
                    <FormField type="password" name="repeat pwd" value="" />
                    <RegisterSubmitRow>
                        <RegisterCardSubmit type="submit" value="Register" />
                    </RegisterSubmitRow>

                    { hasErrors ?
                                    <span class="invalid-feedback" role="alert">
                                        <strong> {errors}</strong>
                                    </span>
                                : ""
                    }
                    
                </RegisterCardForm>
            </RegisterCardWrapper>
        );
    }
}