import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Logo from './Logo';
import styled from 'styled-components';
require('./MenuBar.css')


let MenuGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);

    & a:hover{
        color: white;
    }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }
`;

let LoginGroup = styled.div`
    justify-self: end;

    & a {
        padding: 0 10px;
    }

    & a:hover{
        color: white;
        cursor: pointer;
    }

    @media (max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr;
        justify-self: center;
        padding: 0;

    }
`;

let LoginDropDown = styled.nav`
     position: relative;
     display: inline-block;
`;

let LoginUserName = styled.button`
    cursor: pointer;
    color: white;
    border: none;
    background: #1565C0;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: white;
    font-weight: 500;
    font-size: 18px;
    height: 40px;
    padding: 0px 24px;
   
`;

let LoginUserLogout = styled.div`
    display: none;

    color: white;
    border: none;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    height: 40px;
    width: 108px;
    align-items: center;
    justify-items: center;
`;

let LogoutForm = styled.form`
    display: none;
`;

let LoginUserControlPanel = styled.div`
    display: none;

    color: white;
    border: none;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    height: 40px;
    width: 108px;
    align-items: center;
    justify-items: center;
`;

let HamburgerMenuButton = styled.button`
    width: 44px;
    height: 44px;
    border-color: white;
    background-color: black;
    color: white;
    position: fixed;
    right: 20px;
    top: 20px;
    border-radius: 5px;
    display: none;

      @media (max-width: 600px) {
        display: block;
    }

`;

class MenuBar extends Component { 

    constructor(props){
        super(props)

        let isGuest = (typeof this.props.username == 'undefined' ||  this.props.username == null ) ? true : false

        this.state = {
            isGuest: isGuest,
            userName: this.props.username ?? "User Name",
            adminLevel: this.props.adminLevel ?? 10,
            hasScrolled: false,
            desplegado: false,
        }

        this.handleScroll = this.handleScroll.bind(this)
        this.onClickHambugerMenu = this.onClickHambugerMenu.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);


                //Root div of this component init config on mobile (height=80)
                const RootMenuDiv = document.getElementById('RootMenuDiv');
                      RootMenuDiv.style.cssText = "height: 80px;";
                      RootMenuDiv.parentNode.style.cssText = "height: 80px;";

                //MenuBar component init config on mobile (height=80)
                const MenuBar = document.getElementById('MenuBar');
                    if (MenuBar != null) { MenuBar.style.height = "80px"; }


                
        if ( window.matchMedia("(max-width: 600px)").matches ) {

                  //Now for each of the wrappers in each screen when MenuBar is displayed, 80px height un the first element of the grid
                const blogHeader = document.querySelector("div#blogHeader");
                    if (blogHeader != null) { blogHeader.style.cssText = "grid-template-rows: 80px 100px 100px 1fr 44px;" }

                const portfolioMagicBackground = document.querySelector("div#portfolioMagicBackground");
                    if (portfolioMagicBackground != null) { portfolioMagicBackground.style.cssText = "grid-template-rows: 80px 100px 300px 1fr;" }

                const authorContainer = document.querySelector("div#AuthorContainer");
                    if (authorContainer != null) { authorContainer.style.cssText = "grid-template-rows: 80px 800px 1fr 44px;" }

                //Menu elements hidden onInit
                const MenuGroup = document.getElementById('MenuGroup').childNodes.forEach( (element) => {
                    element.style.display = "none"
                  })

                const LoginGroup = document.getElementById('LoginGroup').childNodes.forEach( (element) => {
                    element.style.display = "none"
                  })
        } 

    }

    handleScroll(event) {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 50) {
            this.setState({ hasScrolled: true })
        } else {
            this.setState({ hasScrolled: false })
        }
    }

    onLogout(event) {

        event.preventDefault();
        document.getElementById('logout-form').submit();

    }

    onEnter() {

        // dropdown
        let logout = document.getElementById('logout-button');
        logout.style.display =  "grid";

        let controlPanel = document.getElementById('panel-button');
        controlPanel.style.display =  "grid";

    }

    onLeave() {

        // dropdown
        let logout = document.getElementById('logout-button');
        logout.style.display =  "none";

        let controlPanel = document.getElementById('panel-button');
        controlPanel.style.display =  "none";

    }

    onClickHambugerMenu(event) {

        event.preventDefault();

        if (this.state.desplegado == true) {

            const MenuBar = document.getElementById('MenuBar');
                if (MenuBar != null) {
                  MenuBar.style.cssText = "height: 80px;";
              }

            const RootMenuDiv = document.getElementById('RootMenuDiv');
                  RootMenuDiv.parentNode.style.cssText =  "height: 80px"
                  RootMenuDiv.style.cssText = "height: 80px;";


            const blogHeader = document.querySelector("div#blogHeader");
                    if (blogHeader != null) { blogHeader.style.cssText = "grid-template-rows: 80px 100px 100px 1fr 44px;" }

            const portfolioMagicBackground = document.querySelector("div#portfolioMagicBackground");
                    if (portfolioMagicBackground != null) { portfolioMagicBackground.style.cssText = "grid-template-rows: 80px 100px 300px 1fr;" }

            const authorContainer = document.querySelector("div#AuthorContainer");
                    if (authorContainer != null) { authorContainer.style.cssText = "grid-template-rows: 80px 800px 1fr 44px;;" }


            if (window.matchMedia("max-width: 600px")) {
                const MenuGroup = document.getElementById('MenuGroup');
                      MenuGroup.childNodes.forEach( (element) => {
                        element.style.display = "none"
                      })

                const LoginGroup = document.getElementById('LoginGroup');
                      LoginGroup.childNodes.forEach( (element) => {
                        element.style.display = "none"
                      })
            }

            this.setState({ desplegado: false })

        } else {

            const MenuBar = document.getElementById('MenuBar');
                  if (MenuBar != null) {
                     MenuBar.style.cssText = "height: 260px; background-color: black";
                  }

            const RootMenuDiv = document.getElementById('RootMenuDiv');
                  RootMenuDiv.parentNode.style.cssText =  "height: 260px"
                  RootMenuDiv.style.cssText = "height: 260px; background-color: black";

            const blogHeader = document.querySelector("div#blogHeader");
                    if (blogHeader != null) { blogHeader.style.cssText = "grid-template-rows: 260px 100px 100px 1fr 44px;" }

            const portfolioMagicBackground = document.querySelector("div#portfolioMagicBackground");
                    if (portfolioMagicBackground != null) { portfolioMagicBackground.style.cssText = "grid-template-rows: 260px 100px 300px 1fr;" }

            const authorContainer = document.querySelector("div#AuthorContainer");
                    if (authorContainer != null) { authorContainer.style.cssText = "grid-template-rows: 260px 800px 1fr 44px;;" }


            if (window.matchMedia("max-width: 600px")){
                const MenuGroup = document.getElementById('MenuGroup');
                      MenuGroup.childNodes.forEach( (element) => {
                        element.style.cssText = "display: grid;"
                      })

                const LoginGroup = document.getElementById('LoginGroup');
                      LoginGroup.childNodes.forEach( (element) => {
                        element.style.cssText = "display: grid;"
                      })
            }

            this.setState({ desplegado: true })
        }

    }

    render(){

        return (
            <div id="RootMenuDiv" className={this.state.hasScrolled ? "MenuBarWrapper hasScrolled" : "MenuBarWrapper"}>
                <Logo className="logo" />
                    <MenuGroup id="MenuGroup" droppedDown={this.state.desplegado}>
                            <a id="homelink" href="/">Home</a>
                            <a id="bloglink" href="/blog">Blog</a>
                            <a id="portfoliolink" href="/portfolio">Portfolio</a>
                            <a id="authorlink" href="/author">Author</a>
                    </MenuGroup>

                    
                { this.state.isGuest ?
                    <LoginGroup  id="LoginGroup">

                        <a id="loginlink" href={ this.props.loginRoute }> { this.props.loginLiteral} </a>
                        <a id="routelink" href={ this.props.registerRoute }> { this.props.registerLiteral } </a>
                    </LoginGroup>
                    :

                        this.state.adminLevel > 6 ?
                            <LoginGroup id="LoginGroup">
                                <LoginDropDown onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                                        <LoginUserName > { this.state.userName } </LoginUserName>
                                        <LoginUserLogout id="logout-button"> <a href={ this.props.logoutRoute} onClick={this.onLogout}> { this.props.logoutLiteral } </a></LoginUserLogout>
                                </LoginDropDown>
                                <LogoutForm id="logout-form" action={ this.props.logoutRoute } method="POST">
                                    <input type="hidden" name="_token" value={ this.props.csrf } />
                                </LogoutForm>
                            </LoginGroup>
                        : 

                        <LoginGroup id="LoginGroup">
                            <LoginDropDown onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                                    <LoginUserName > { this.state.userName } </LoginUserName>
                                    <LoginUserControlPanel id="panel-button"> <a href={ this.props.panelRoute} onClick={this.onPanel}> { this.props.panelLiteral } </a> </LoginUserControlPanel>
                                    <LoginUserLogout id="logout-button"> <a href={ this.props.logoutRoute} onClick={this.onLogout}> { this.props.logoutLiteral } </a></LoginUserLogout>
                            </LoginDropDown>
                            <LogoutForm id="logout-form" action={ this.props.logoutRoute } method="POST">
                                <input type="hidden" name="_token" value={ this.props.csrf } />
                            </LogoutForm>
                        </LoginGroup> 
            }
            <HamburgerMenuButton onClick={ this.onClickHambugerMenu }> &#9776; </HamburgerMenuButton>
            </div>
        );
    }

}

export default MenuBar;

if (document.getElementById('MenuBar')) {

    let menuBar = document.getElementById('MenuBar');
    let csrf = menuBar.getAttribute('csrf');
    let username = menuBar.getAttribute('username');
    let loginRoute = menuBar.getAttribute('loginRoute');
    let logoutRoute = menuBar.getAttribute('logoutRoute');
    let registerRoute = menuBar.getAttribute('registerRoute');
    let logoutLiteral = menuBar.getAttribute('logoutLiteral');
    let loginLiteral = menuBar.getAttribute('loginLiteral');
    let registerLiteral = menuBar.getAttribute('registerLiteral');
    let adminLevel =  menuBar.getAttribute('adminLevel');
    let panelRoute =  menuBar.getAttribute('panelRoute');
    let panelLiteral =  menuBar.getAttribute('panelLiteral');
    ReactDOM.render(<MenuBar csrf = {csrf}
                             username = {username}
                             loginRoute = {loginRoute}
                             logoutRoute = {logoutRoute}
                             registerRoute = {registerRoute}
                             logoutLiteral = {logoutLiteral}
                             loginLiteral = {loginLiteral}
                             registerLiteral = {registerLiteral}
                             adminLevel =  {adminLevel}
                             panelRoute =  {panelRoute}
                             panelLiteral =  {panelLiteral}


     />, document.getElementById('MenuBar'));
}