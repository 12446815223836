import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';


class Logo extends Component {

    render() {

    let Logo = styled.div`
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        color: white;

        & a:hover {
            color: white;
        }
    `;

        return (
                <Logo>
                    <a id="logolink" href="/">Iflet.tech</a>
                </Logo>
        );
    }
}

export default Logo;

if (document.getElementById('Logo')) {
    ReactDOM.render(<Logo />, document.getElementById('Logo'));
}