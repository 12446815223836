import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import BlogCard from './BlogCard';


let colorsArray = {
	PURPLE: {
		COLOR1 : "#CC4DA1",
		COLOR2 : "#8C13B8"
	},
	RED: {
		COLOR1 : "#C9522C",
		COLOR2 : "#8B0B0B"
	},
	BLUE: {
		COLOR1 : "#2598EC",
		COLOR2 : "#133FB8"
	},
	GREEN: {
		COLOR1 : "#1BC618",
		COLOR2 : "#7C992B"
	}	
};

let BlogBodyWrapper = styled.div`
	    display: grid;
		justify-items: center;

		@media (max-width: 600px) {
	
			grid-template-columns: 1fr;
		}
`;

let BoxWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 60px;
	justify-items: center;

	@media (max-width: 1200px) {
				grid-template-columns: repeat(2, 1fr);
		}

	@media (max-width: 850px) {
	
			grid-template-columns: repeat(1, 1fr);
		}
	
	@media (max-width: 600px) {
	
		grid-template-columns: 1fr;
		padding: 0;
	}

`;

class BlogBody extends Component {

	colorCalculator(index) {

			let colorIndex = (index+1)%4
			 switch (colorIndex) {
				 case 1: 	return colorsArray.PURPLE;
				 case 2: 	return colorsArray.RED;
				 case 3: 	return colorsArray.BLUE;
				 default: 	return colorsArray.GREEN;
			 }
		}

    render() {

		let articlesObject = JSON.parse(this.props.articles);



        return (

            <BlogBodyWrapper>
				<BoxWrapper>
				{ articlesObject.map((article, index) => (
								<BlogCard
									key = {index}
									title = {article.title}
									slug = { article.slug }
									// text =  {article.body}
									updatedAt = { article.updatedDate }
									author = { article.author }
									tiempolectura = { article.tiempolectura}
									color = { this.colorCalculator(index) }
								/> 
						)
				)}
				</BoxWrapper>
            </BlogBodyWrapper>
        
        );
    }
}

export default BlogBody;