import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';



class BlogCard extends Component {

    render() {
			
		let BlogCardWrapper = styled.div`
			width: 360px;
			z-index: 10;
			
		`;

		let BlogLink = styled.a`
			    display: grid;
			    background: linear-gradient(180deg, ${ this.props.color.COLOR1 } 0%, ${ this.props.color.COLOR2 } 100%);
				border-radius: 30px;
				box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
				transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
			    grid-template-columns: 1fr;
			    align-self: center;
			    justify-self: center;
			    &:hover {
			    		color: white;
						transform: scale(1.05, 1.05);
						box-shadow: 0 50px 80px rgba(0, 0, 0, 0.3);
					}
		`;

		let BlogTitle = styled.div`
			font-size: 30px;
		    font-weight: 800;
		    line-height: 120%;
		    text-align: center;
		    align-self: center;
		    justify-self: center;
		    padding: 36px;

		`;

		let BlogCardDataSection = styled.div`
			background: rgba(255, 255, 255, 0.4);
		    backdrop-filter: blur(40px);
		    border-radius: 0 0 30px 30px;
		    padding: 20px 36px;
		    display: grid;
		    grid-template-rows: 1fr 1fr;
		`;

		let BlogCardDataSubSection = styled.div`
			 grid-column-gap: 15px;
	   		 align-self: center;
		`;

		let BlogCardFecha = styled.div`
			font-size: 16px;
    		font-weight: 300;
		`;

		let BlogCardTiempoLectura = styled.div`
		 	font-size: 14px;
	    	font-weight: 600;
	    `;



		let AuthorWrapper = styled.div`
			display: grid;
			grid-template-columns: 1fr 60px;
			justify-self: end;
			align-self: end;
			align-items: center;
		`;

		let AuthorName = styled.div`
			  padding: 20px;
			  font-size: 16px;
			  font-weight: 600;
		`;

		let AuthorImage = styled.div`
			width: 50px;
			height: 50px;
			background-image: url("images/edu_image.svg");
			// background-image: url("https://gravatar.com/avatar/0815692b7f41f457794ce8bc89ab8c79?s=400&d=robohash&r=x");
			background-size: contain;
			border-style: solid;
		    border-radius: 50%;
		    border-color: white;
		`;
		

		let link =  "/article/" + this.props.slug;

        return (
            <BlogCardWrapper>
               <BlogLink href={link}>
               
				<BlogTitle> {this.props.title} </BlogTitle>
				<BlogCardDataSection>
					<BlogCardDataSubSection>
						<BlogCardFecha> {this.props.updatedAt} </BlogCardFecha>
						<BlogCardTiempoLectura> Tiempo de lectura: {this.props.tiempolectura} mins </BlogCardTiempoLectura>
					</BlogCardDataSubSection>
					
					<AuthorWrapper>
						<AuthorName>{this.props.author}</AuthorName>
						<AuthorImage />
					</AuthorWrapper>
				</BlogCardDataSection>
				</BlogLink>
			</BlogCardWrapper>         
		); 
    }
}



export default BlogCard;