import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import FormField from './FormField';


let LoginCardWrapper = styled.div`
            width: 570px;
            height: 459px;
            filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.2));
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(40px);
            border-radius: 30px;
            box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);

            display: grid;
            grid-template-rows: 160px 1fr;

            @media (max-width: 600px) {
                width: 90%;
                height: 100%;
            }
        
        `;

        let LoginCardTitle = styled.div`
            font-weight: bold;
            font-size: 80px;
            line-height: 95px;
            align-self: center;
            justify-self: center;
            color: #1565C0;
        `;
        let LoginCardForm = styled.form`
            display: grid;
            grid-template-rows: 48px 48px 1fr;
            justify-items: center;
        `;
        
        let LoginCardButtons = styled.div``;

        let LoginCardSubmit = styled.input`
            background: #1565C0;
            color: white;
            font-weight: 500;
            font-size: 18px;
            box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            display: grid;
            height: 40px;
            padding: 0px 24px;
            border: 0;

            justify-self: center;
            align-self: center;
        `;

        let LoginCardButtonsRow = styled.div`
            display: grid;
            grid-template-columns: 100px 25px 130px;
            grid-auto-rows: 50px;
            align-items: center;
        `;

        let LoginCardCheckBox = styled.input`
            justify-self: center;
            align-self: center;
        `;

        let LoginCardCheckBoxLabel = styled.label`
            color: black;
            font-weight: 500;
            font-size: 18px;
            justify-self: start;
            align-self: center;
            margin: 0;
            padding: 0;
        `;

        let LoginCardButtonsRow2 = styled.div`
            display: grid;
            grid-template-columns: 250px;
            grid-auto-rows: 20px;
            align-items: end;
        `;

        let LoginCardForgot = styled.a`
            color: #1565C0;
            font-size: 13px;
            font-weight: 500;
            line-height: 130%;
        `;

        let LoginCardRegister = styled.div`
            color: black;
            font-size: 13px;
            font-weight: 500;
            line-height: 130%;

            & a {
                color: #1565C0;
            }
        `;

        let FormFieldWrapper = styled.label`
            display: grid;
            grid-template-rows: 30px;
            grid-template-columns: 110px 330px;
            align-items: center;
            column-gap: 16px;

            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            justify-self: end;
        `;
      
        let FormFieldInput = styled.input`
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 5px;
            border: 0;
            padding: 0 1rem;
            text-align: center;
        `;

export default class LoginCard extends Component {

    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: '',
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }


    handleChangeName(event) {
        this.setState({ name: event.target.value });
    }

    handleChangePassword(event) {
        this.setState({ name: event.target.value });
    }



    handleSubmit(event) {
        event.preventDefault();
        alert('Email: ' + this.state.email);
        alert('Password: ' + this.state.password);
    }
    
    render() {
  
        
        return (
            <LoginCardWrapper>
                <LoginCardTitle>Login</LoginCardTitle>
                <LoginCardForm method="POST" onSubmit={this.handleSubmit}>
                    <input type="hidden" name="_token" value={ this.props.csrf } />
                    <FormFieldWrapper>
                        Email
                        <input type="text" value="" onChange={this.handleChangeName}/>
                    </FormFieldWrapper>

                    <FormFieldWrapper>
                        Password
                        <input type="password" value="" onChange={this.handleChangePassword}/>
                    </FormFieldWrapper>
                    
                    {/* <FormField type="text" id="email" name="e-mail" value={ this.state.email } onChange={this.handleChange} /> */}
                    {/* <FormField type="password" id="password" name="password" value={ this.state.password } onChange={this.handleChange} /> */}
                    <LoginCardButtons>
                        <LoginCardButtonsRow>
                            <LoginCardSubmit type="submit" value="LogIn" />
                            <LoginCardCheckBox type="checkbox" value="false" name="rememberMe" /> <LoginCardCheckBoxLabel> Recuérdame </LoginCardCheckBoxLabel>
                        </LoginCardButtonsRow>
                        <LoginCardButtonsRow2>
                            <LoginCardForgot href=""> He olvidado mi contraseña </LoginCardForgot>
                            <LoginCardRegister> No estoy registrado. <a href="">Regístrarme</a></LoginCardRegister>
                        </LoginCardButtonsRow2>      
                    </LoginCardButtons>
                </LoginCardForm>
                
            </LoginCardWrapper>


        );
    }
}