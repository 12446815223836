import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import RegisterCard from './Cards/RegisterCard';
import Footer from '../Footer';
import MenuBar from '../MenuBar';

let RegisterContainer = styled.div`
    display: grid;
    grid-template-rows: 1200px 44px;
    grid-template-columns: 1fr;
    transform: rotate(15);

    @media(max-width: 600px) {
        grid-template-rows: 245px 100px 100px 1fr 44px;
    }
`;


let BlogMagicBackground = styled.div`
    background-image: url(../images/BlogBackground.svg);
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    
    display: grid;
    grid-template-rows: 80px 1fr;

    @media(max-width: 600px) {
       background-size: 270%;
    }
`;

let MenuBarWrapper = styled.div`
    width: 100%;
    align-self: center;
    justify-self: center;
    display: grid;
    grid-template-rows: 80px;
    grid-row: 1;
    grid-column: 1;


    @media(max-width: 600px) {
       align-self: normal;
    }
`;

let Section = styled.div`
    display: grid;
    justify-self: center;
`;

export default class Register extends Component {

    constructor(props){
        super(props);
    }


    render() {


        return (
                <RegisterContainer>
                        <BlogMagicBackground>
                            <MenuBarWrapper>
                                    <MenuBar />
                            </MenuBarWrapper>
                            <Section>
                                <RegisterCard  action={ this.props.action } 
                                               csrf={ this.props.csrf }
                                               errors={ this.props.errors}
                                               oldname= { this.props.oldname}
                                               />
                            </Section>
                        </BlogMagicBackground>
                        <Footer />
                </RegisterContainer>
        );
    }
}

if (document.getElementById('register')) {
    let csrf = document.getElementById('register').getAttribute('csrf');
    let action = document.getElementById('register').getAttribute('action');
    let errors = document.getElementById('register').getAttribute('errors');
    let oldname = document.getElementById('register').getAttribute('oldname');
    ReactDOM.render(<Register csrf={csrf} action={action} errors= {errors} oldname={oldname}/>, document.getElementById('register'));
}