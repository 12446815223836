import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

class Linkedin extends Component {


    render(){

        let LinkedinContainer = styled.div`
            width: 140px;
            height: 30px;
            margin-bottom: 5px;
        `;
        let LinkedinLogo = styled.div`
            & img {
                width: 100%;
                height: 100%;
            }
        `;


        return(
            <LinkedinContainer>
                <LinkedinLogo>
                <a href="https://www.linkedin.com/in/educalero/"> <img src="images/linkedin_myprofile_160x33.png" alt=""/></a>   
                </LinkedinLogo>
            </LinkedinContainer>
        );
    }
}

export default Linkedin;