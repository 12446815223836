import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

class Section extends Component {

    render(){

        let SectionWrapper = styled.div`
            max-width: 1000px;
            height: 500px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-self: center;

            @media (max-width: 1050px){
                grid-row-gap: 30px;
                grid-template-columns: 630px;
            }

            @media (max-width: 600px){
                grid-row-gap: 30px;
                grid-template-columns: 1fr;
            }
        `;

        let SectionLeft = styled.div`
            display: grid;
            grid-template-columns: 1fr;
            justify-items: center;
            
            @media (max-width: 600px){
                &  img {
                    width: 80%;
                }
            }
        `;

        let SectionRight = styled.div`
            color: white;
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 20px;

            @media (max-width: 600px) {
                justify-items: center;
            }
        `;
        let TextSectionTitle = styled.div`
            align-self: center;
            font-size: 60px;
            font-weight: 700;

        `;
        let TextSectionText = styled.div`
              font-size: 20px;
                font-weight: 500;
            
            @media (max-width: 600px) {
                width: 80%;
            }
        `;

        return(
            <SectionWrapper>
                        <SectionLeft>
                                <img src= "images/undraw_developer_activity_bv83.svg" alt="developer image"/>
                        </SectionLeft>
                        
                        <SectionRight>
                                <TextSectionTitle>
                                    Bienvenido
                                </TextSectionTitle>

                                <TextSectionText>
                                Iflet.tech es un espacio donde compartir y comentar trucos y experiencias relacionadas con el desarrollo de aplicaciones web y móviles.
    Los artículos y ejemplos que encontrarás estan relacionados con las últimas teconologías: Swift, SwiftUI, Combine, Kotlin, React. Laravel ¡y mucho más!
                                </TextSectionText>
                        </SectionRight>
            </SectionWrapper>
        );
    }
}

export default Section;