import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import styled from 'styled-components';
import MenuBar from '../MenuBar';
import Linkedin from '../Views/Linkedin';
import GitHubButton from 'react-github-btn';

let AuthorContainer = styled.div`
    background-image: url(../images/AuthorBackground.svg);
    width: 100%;
    height: 100%;
    background-size: 100% 130%;
    background-position-y: 90%;
    background-repeat: no-repeat;
    display: grid;
    grid-template-rows: 80px 400px 900px 44px;

    @media(max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 245px 800px 1fr 44px;
        background-size: 200% 200%;
        background-position-y: 50%;
        background-position-x: 80%;
    }
`;

let MenuBarWrapper = styled.div`

    & a {
           color: #F8CE8B;
    }


    // @media (max-width: 600px) {
    //     & a {
    //        color: #4F4F4F;
    //     }
    }

`;


let Section = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    @media(max-width: 600px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }
`;

let AuthorImage = styled.div`
    justify-self: end;
    width: 300px;
    height: 300px;
    background-color: #F8CE8B;
    border-radius: 50%;
    & img {
        width: 100%;
        height: 100%;
    }

    @media(max-width: 600px) {
        justify-self: center;
        align-self: center;
        width: 200px;
        height: 200px;
    }
`;

let AuthorText = styled.div``;
let MainTextBox = styled.div`
    color: white;
    font-size: 30px;
    font-weight: 800;
`;

let TextBox1 = styled.div``;
let SubTextBox1 = styled.div``;
let Text1 = styled.div`
    font-size: 25px;
`;

let Text2 = styled.div`
     font-size: 35px;
`;

let Text3 = styled.div`
    font-size: 25px;
`;

let SubTextBox2 = styled.div`
    padding-bottom: 5px;
    font-size: 13px;
    font-weight: 300;
    & a {
        font-weight: 800;
    }
    & a:hover { color: #F8CE8B }
`;

let DonwloadBox = styled.div`
    align-self: center;
    font-weight: 100;
    font-size:  12px;
    padding: 5px;
    width: 160px;
    display: grid;
    grid-template-columns: 1fr 2fr;

    background: rgba(0, 0, 0, 0.6);
    border: 4px solid #000000;
    box-shadow: 0px 40px 100px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px) saturate(150%);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
`;

let DownloadImage = styled.div``;
let DownloadText = styled.div``;
let SectionArticle = styled.div`
    width: 600px;
    color: black;
    font-size: 15px;
    font-weight: 300;
    justify-self: center;
    display: grid;
    grid-template-columns: 2fr 1fr;

    @media(max-width: 600px) {
        width: 100%;
        grid-template-columns: 1fr;
    }
`;

let Article = styled.div` 
    justify-self: end;

    @media(max-width: 600px) {
       justify-self: center;
       padding: 30px 20px;
    }
`;

let ArticleTitle = styled.div`
    font-size: 28px;
    font-weight: 600;

`;
let ArticleText = styled.div``;


export default class Author extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        
        
        return (
            <AuthorContainer id="AuthorContainer">
                <MenuBarWrapper id="MenuBar">
                        <MenuBar 
                                username={this.props.username}
			                    csrf={ this.props.csrf} 
			                    logoutLiteral={ this.props.logoutLiteral }  
			                    logoutRoute={ this.props.logoutRoute }
                                loginLiteral={ this.props.loginLiteral }
                                loginRoute={ this.props.loginRoute}
                                registerLiteral={ this.props.registerLiteral}
                                registerRoute={ this.props.registerRoute}
                                adminLevel = {this.props.adminLevel}
                                panelRoute = {this.props.panelRoute}
                                panelLiteral = {this.props.panelLiteral}
                            />
                </MenuBarWrapper>
                <Section>
                    <AuthorImage>
                        <img src="images/edu_image.svg"/>
                    </AuthorImage>
                    <AuthorText>
                        <MainTextBox>
                            <TextBox1>
                                <SubTextBox1>
                                    <Text1>Hola, soy</Text1>
                                    <Text2>Eduard Calero</Text2>
                                    <Text3>Software Developer</Text3>
                                </SubTextBox1>
                                <SubTextBox2>
                                    <div>¿Quieres Contratarme? <a href="mailto:educalerorovira@gmail.com">Contáctame</a></div>
                                    <div><a href="https://twitter.com/CaleroEduard?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-show-count="false">Follow @CaleroEduard</a></div>
                                    <GitHubButton href="https://github.com/UOCecalero" aria-label="Follow @UOCecalero on GitHub">Follow @UOCecalero</GitHubButton>
                                    <Linkedin />
                                    {/* <div class="LI-profile-badge"  data-version="v1" data-size="medium" data-locale="es_ES" data-type="horizontal" data-theme="dark" data-vanity="educalero"><a class="LI-simple-link" href='https://es.linkedin.com/in/educalero?trk=profile-badge'>Eduard Calero Rovira</a></div> */}

                                </SubTextBox2>
                            </TextBox1>
                            {/* <DonwloadBox>
                                    <DownloadImage>
                                        <img></img>
                                    </DownloadImage>
                                    <DownloadText>
                                        <div>Descarga mi CV</div>
                                        <div>English</div>
                                    </DownloadText>
                            </DonwloadBox> */}
                        </MainTextBox>
                    </AuthorText>
                </Section>
                <SectionArticle>
                    <Article>
                    <ArticleTitle>
                            Mi historia
                        </ArticleTitle>
                        <ArticleText>
                            Como ya sabrás si has navegado por las páginas de iflet.tech, igual que tú soy un entusiasta de la tecnología. <br/>
    Empecé a una edad muy temprana, la primera vez que pude trastear un "486". <br/>
    No recuerdo con precisión qué edad que tenía pero lo que si recuerdo es que me quedé atrapado entendiendo como funcionaba aquel aparato y como se le podían mandar acciones mediante comandos en MSDOS. <br/> <br/>

    Desde entonces he tenido una relación constante con el mundo digital y tecnológico.<br/>
    Acabé estudiando telecomunicaciones en la Universitat Politécnica de Catalunya y he trabajado durante más de 10 años en mundo audiovisual y televisivo. <br/>
    Sin embargo mis inquietudes y creciente hambre por entender como funcionan las cosas, me han empujado todo el tiempo a aprender cosas nuevas de manera autodidacta. Disfruto aprendiendo como funcionan las cosas al más bajo nivel. <br/> <br/>

    En 2013 mi curiosidad por el mundo del software libre me llevó a hacer el Master en Software Libre  que en aquel momento impartía la UOC, y tras este master empecé a ampliar significativamente mis skills tecnológicos relacionados con la programación. Sobretodo relacionados con las tecnologías mas importantes en el mundo de internet y las aplicaciones web y móviles. <br/>

    En la actualidad me interesan dentro del campo tencológico especialmente los proyectos relacionados con tecnología blockchain, realidad aumentada e inteligencia artificial, aunque en algunos de estos campos aún no tenga un conocimiento a un nivel avanzado. <br/>
                        </ArticleText>
                    </Article>
                </SectionArticle>
                <Footer />
            </AuthorContainer>
            
        );
    }
}

if (document.getElementById('author')) {
    let author = document.getElementById('author');
    let username = author.getAttribute('username');
    let csrf = author.getAttribute('csrf');
    let loginRoute = author.getAttribute('loginRoute');
    let logoutRoute = author.getAttribute('logoutRoute');
    let registerRoute = author.getAttribute('registerRoute');
    let logoutLiteral = author.getAttribute('logoutLiteral');
    let loginLiteral = author.getAttribute('loginLiteral');
    let registerLiteral = author.getAttribute('registerLiteral');
    let adminLevel = author.getAttribute('adminLevel');
    let panelRoute = author.getAttribute('panelRoute');
    let panelLiteral = author.getAttribute('panelLiteral');
    
    ReactDOM.render(<Author username={username}
                            csrf = {csrf}
                            loginRoute = {loginRoute}
                            logoutRoute = {logoutRoute}
                            registerRoute = {registerRoute}
                            logoutLiteral = {logoutLiteral}
                            loginLiteral = {loginLiteral}
                            registerLiteral = {registerLiteral}
                            adminLevel = {adminLevel}
                            panelRoute = {panelRoute}
                            panelLiteral = {panelLiteral}
                    />, document.getElementById('author'));
}